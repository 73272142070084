.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-eggshell);
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 5rem;
  justify-content: center;
}

.banner {
  color: var(--color-sap);
  font-family: var(--font-vintage);
  font-size: 5rem;
  font-weight: 400;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.81rem;
}

.Button {
  padding: 1.5rem 0rem;
}

.loginText {
  font-family: var(--font-josefin);
  font-size: 20px;
  text-align: center;
  color: var(--color-sap);
  margin: 1rem;
}

.loginText a {
  color: var(--color-sap);
  cursor: pointer;
}

.error {
  font-size: 20px;
  font-family: var(--font-josefin);
  color: var(--color-flame);
}

.success {
  font-size: 20px;
  font-family: var(--font-josefin);
  color: var(--color-sap);
  margin: 2rem;
  text-align: center;
}
