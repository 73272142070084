body {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: JosefinSans;
  src: local("JosefinSans"),
    url(./fonts/JosefinSans-VariableFont_wght.ttf) format("opentype");
}

@font-face {
  font-family: Vintage;
  src: local("Vintage"), url(./fonts/VIntage.otf) format("opentype");
}
#root {
  height: 100%;
}
:root {
  height: 100%;
  --font-josefin: "JosefinSans", sans-serif;
  --font-vintage: "Vintage", serif;

  --color-eggshell: #f3ecd1;
  --color-sap: #367227;
  --color-cosmiclatte: #f9f5e7;
  --color-flame: #e4572e;
  --color-forest: #274d1e;
}
