.page {
  display: flex;
  flex-direction: column;
  background-color: var(--color-eggshell);
  position: relative;
  overflow: hidden;
}

/* Banner */
.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 57.68rem;
  align-self: center;
  height: 70vh;
  max-height: 50rem;
  justify-content: center;
}

.banner h1 {
  color: var(--color-sap);
  font-family: var(--font-vintage);
  font-weight: 400;
  font-size: 5rem;
  text-align: center;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-top: 3.5rem;
}

.banner Button {
  margin: 0 1rem;
}

.foodBar {
  width: 207.4375rem;
  height: 23.9375rem;
}

.convenience {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-sap);
  height: auto;
}

.convenienceText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -10vw;
  margin-bottom: -15vw;
}

.food {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  position: relative;
  top: -25rem;
}

.food img {
  margin-right: 1rem;
  width: 100%;
  height: auto;
}

.convenienceText h2 {
  font-family: var(--font-vintage);
  font-weight: 400;
  font-size: 4rem;
  color: var(--color-eggshell);
  text-align: center;
}

.community h2 {
  font-family: var(--font-vintage);
  font-weight: 400;
  font-size: 4rem;
  color: #367227;
  text-align: center;
}

.community {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 12.37rem;
}

.communityCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

.communityCardContainer img {
  margin-left: -1rem;
  width: 12.125rem;
}

.own {
  display: flex;
  width: 68rem;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2.2rem;
}

.ownText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  min-width: 38.43rem;
}

.ownBlob {
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: flex-end;
  max-width: 100%;
  z-index: 1;
  overflow: auto;
  margin-left: 40rem;
}

/* .own img {
  width: 53.202rem;
  height: 45.23663rem;
} */

.worryFree {
  display: flex;
  width: 68rem;
  align-self: center;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12.37rem;
}

.worryFreeText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  align-items: flex-end;
  min-width: 33.375rem;
}

.safe {
  display: flex;
  width: 68rem;
  align-self: center;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2.125rem;
}

.safeBean {
  display: flex;
  width: 82.5rem;
  align-self: center;
  margin-bottom: 2.56rem;
  justify-content: center;
}

.subtext1 {
  font-family: var(--font-josefin);
  font-weight: 400;
  font-size: 1.5rem;
  color: #e4572e;
  text-align: left;
  line-height: 120.5%;
  margin-top: 1rem;
}

.subtext2 {
  font-family: var(--font-josefin);
  font-weight: 400;
  font-size: 1.5rem;
  color: #e4572e;
  text-align: right;
  line-height: 120.5%;
  margin-top: 1rem;
}

.subtext2alt {
  margin-top: 1rem;
}

.subtext3 {
  font-family: var(--font-josefin);
  font-weight: 400;
  font-size: 1.5rem;
  color: #e4572e;
  text-align: center;
  line-height: 120.5%;
}

.convenienceList {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.banner2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 57.68rem;
  align-self: center;
  margin-top: 15rem;
  margin-bottom: 38.5rem;
}

.banner2 h1 {
  color: var(--color-sap);
  font-family: var(--font-vintage);
  font-weight: 400;
  font-size: 5rem;
  text-align: center;
}

.banner2 Button {
  margin: 0 1rem;
}

.convenienceList p {
  font-family: var(--font-josefin);
  font-weight: 400;
  font-size: 2.25rem;
  color: var(--color-eggshell);
  padding: 1rem;
  margin: 0;
}

.shadow {
  color: var(--color-eggshell);
  font-family: Vintage;
  font-size: 5rem;
  font-weight: 400;
  line-height: 7rem;
  letter-spacing: 0em;
  text-align: center;
}

/*Vertical Sliding*/
.slidingVertical {
  display: inline;
  text-indent: 0.5rem;
}

.slidingVerticalPath {
  animation: topToBottom 20s cubic-bezier(0.8, -0.53, 0.01, 1.84) infinite 0s;
  color: #e4572e;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

.slidingVerticalPath:nth-child(2) {
  animation-delay: 5s;
}

.slidingVerticalPath:nth-child(3) {
  animation-delay: 10s;
}

.slidingVerticalPath:nth-child(4) {
  animation-delay: 15s;
}

/*topToBottom Animation*/
@keyframes topToBottom {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    transform: translateY(-3.125rem);
  }

  10% {
    opacity: 1;
    transform: translateY(0rem);
  }

  25% {
    opacity: 1;
    transform: translateY(0rem);
  }

  30% {
    opacity: 0;
    transform: translateY(3.125rem);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.simple {
  font-family: Vintage;
  font-style: normal;
  font-size: 5rem;
  font-weight: 400;
  line-height: 7rem;
  letter-spacing: 0em;
  text-align: center;
  color: #367227;
}

.communityAlt {
  display: none;
}

@media (max-width: 1150px) {
  .infiniteloop {
    margin-bottom: 5rem;
  }

  .convenienceText {
    margin-top: 2rem;
    flex-direction: column;
    margin-bottom: 0;
    width: 100%;
  }

  .convenienceText img {
    display: none;
  }

  .convenienceList {
    max-width: 25rem;
  }

  .convenienceText h2 {
    margin-bottom: 2rem;
  }

  .convenienceList p {
    font-size: 2.8rem;
    margin: 0;
    width: 100%;
  }

  .community {
    margin-bottom: 10rem;
  }

  .communityCardContainer {
    display: none;
  }

  .communityText {
    display: none;
  }

  .communityAlt {
    display: inline;
    width: 488px;
    height: 464px;
    margin-top: 2rem;
  }

  .own {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0rem;
  }

  .subtext1 {
    margin-top: 2rem;
    max-width: 35rem;
    font-size: 1.75rem;
  }

  .cardAndBlob {
    display: none;
  }

  .ownText {
    align-items: center;
  }

  .worryFree {
    width: 50rem;
    justify-content: center;
    margin-right: 22.5rem;
    margin-bottom: 0;
  }

  .worryFreeText {
    position: relative;
    min-width: 17.5rem;
    justify-content: space-between;
  }

  .worryFree img {
    scale: 0.6;
  }

  .checklist {
    width: 395px;
    height: 159px;
  }

  .subtext2 {
    font-size: 1.75rem;
    min-width: 27.5rem;
  }

  .safe {
    align-items: center;
    justify-content: center;
    margin-right: 5rem;
  }

  .safeBean {
    scale: 0.6;
    margin-top: -5rem;
  }

  .banner2 {
    margin-top: 5rem;
    margin-bottom: 30rem;
  }

  .banner2 h1 {
    color: var(--color-sap);
    font-family: var(--font-vintage);
    font-weight: 400;
    font-size: 4rem;
    text-align: center;
    margin-bottom: 0rem;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    scale: 1.25;
  }
}

@media (max-width: 800px) {
  .banner {
    scale: 0.6;
    max-height: 25rem;
  }

  .privateDining {
    scale: 0.75;
  }

  .simple {
    scale: 0.75;
    margin-top: -2rem;
  }

  .infiniteloop {
    scale: 0.5;
    margin-top: -3rem;
  }

  .convenience {
    margin-top: -2rem;
  }

  .convenienceText {
    scale: 0.6;
    margin-top: -5rem;
    margin-bottom: -7.5rem;
  }

  .convenienceText h2 {
    font-size: 3.25rem;
  }

  .community {
    scale: 0.5;
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .own {
    scale: 0.5;
    margin-top: -2.5rem;
    margin-bottom: -17.5rem;
  }

  .ownText {
    align-items: center;
  }

  .worryFree {
    scale: 0.45;
    margin-right: 10rem;
    margin-bottom: -12.5rem;
  }

  .safe {
    scale: 0.4;
    margin-bottom: -5rem;
  }

  .safeBean {
    scale: 0.3;
  }

  .banner2 {
    scale: 0.6;
    margin-top: -10rem;
    margin-bottom: 22.5rem;
  }

  .banner2 h1 {
    font-size: 2.9rem;
  }
}
