.Calendar {}

.main {
    display: flex;
    flex-direction: row;
    gap: 4.06rem;
    align-items: center;
    /* max-height: 50%; */
    /* scale:0.8 */
}

.timeContainer {
    display: flex;
    flex-direction: column;
}

.timePills::-webkit-scrollbar {
    width: 0 !important;
  }

.timeTitle {
    margin-bottom: 0.875rem;
    font-family: var(--font-josefin);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: var(--color-flame);
    display: flex;
    align-items: center;
}

.timePills {
    display: flex;
    flex-direction: row;
    gap: 0.875rem;
    flex-wrap: wrap;
    width: 24.25rem;
    margin-bottom: 3rem;
    max-height: 20.75rem;
    padding: 1rem;
    overflow-y: scroll;
}

.nextButtonContainer {
    display: flex;
    justify-content: center;
}