.page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-eggshell);
  /* padding-bottom: 3rem; */
  height: 100vh;
  overflow: hidden;
}

.cardContainer {
  display: flex;
  /* background-color: blue; */
  flex-direction: column;
  height: 100%;
  justify-content: center;
  /* align-items: center; */
}

.navbarContainer {
  display: flex;
  width: 100%;
  /* position: absolute; */
  top:0;
  /* background-color: red; */
}

.card {
  display: flex;
  background-color: var(--color-cosmiclatte);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  margin-bottom: 2.5rem;
  min-height: 75%;
  min-width: 85vw;
  box-shadow: 0px 4px 45px -9px rgba(0, 0, 0, 0.25);
  border-radius: 34px;
  gap: 2.5rem;
  position: relative;
}

.cardTitle {
  font-family: "Vintage";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #347326;
}

.backButton {
  position: absolute;
  left: 3rem;
  top: 3rem;
}

@media (max-height: 850px) {
  .card {
    scale: 0.98;
    margin-top: -1rem;
  }
}