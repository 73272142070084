.page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-eggshell);
  /* padding-bottom: 3rem; */
  height: 100vh;
}

.cardContainer {
  display: flex;
  /* background-color: blue; */
  flex-direction: column;
  height: 100%;
  justify-content: center;
  /* align-items: center; */
}

.contentContainer {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 1.5rem */
}

.navbarContainer {
  display: flex;
  width: 100%;
  top:0;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  max-width: 12.7rem;
  margin-bottom: 2.7rem;
}

.nextButtonContainer {
  margin-top: 2rem;

}

.card {
  display: flex;
  background-color: var(--color-cosmiclatte);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  margin-bottom: 2.5rem;
  min-height: 75%;
  min-width: 85vw;
  box-shadow: 0px 4px 45px -9px rgba(0, 0, 0, 0.25);
  border-radius: 34px;
  gap: 2.6875rem;
}

.cardTitle {
  font-family: "Vintage";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #347326;
  margin-bottom: 2rem;
}

.subtitle {
  font-family: var(--font-josefin);
  font-size: 1.25rem;
}
