/* Looper styles */
@keyframes slideAnim {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper {
  height: 4vh;
  width: 100%;
}

.looper__innerList {
  display: flex;
  justify-content: center;
  width: fit-content;
}

.looper__innerList[data-animate="true"] .looper__listInstance {
  animation: slideAnim linear infinite;
}

.looper__listInstance {
  display: flex;
  width: max-content;

  animation: none;
}

/* Example content styles */
/* :root {
  --green: #59c3c3;
  --offwhite: #ebebeb;
  --red: #e65a5e;
} */
/* html {
  height: 100%;
}
body {
  height: 100%;
  display: flex;
  flex-direction: column;
} */
/* #root {
  flex: 1;
  padding: 16px;
  font-family: sans-serif;
  color: var(--offwhite);
  background: linear-gradient(-45deg, #4c468b, #05031a);
} */
.description {
  margin-bottom: 16px;
  text-align: center;
}
.contentBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  margin: 10px;
  padding: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 16px;
}
.contentBlock--one {
  width: 120px;
  background: rgba(255, 255, 255, 0.05);
  font-weight: 600;
  font-size: 18px;
}
.contentBlock--one:last-of-type {
  color: var(--green);
  margin-right: 36px;
}
.contentBlock--two {
  color: var(--green);
  font-size: 48px;
  background: rgba(255, 255, 255, 0.05);
}
