.page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background-color: var(--color-eggshell);
  overflow-y: hidden;
}

.navbar {
  position: absolute;
  left: 0rem;
  top: 1.83rem;
}

.cardContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: scroll;
  /* width: 100vw; */
  margin: -3rem 0rem;
  padding: 3rem 3rem 5rem 3rem;
  gap: 3rem;
}

.cards::-webkit-scrollbar {
  width: 0 !important;
}

