.page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-eggshell);
  height: 100vh;
  overflow: hidden;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.navbarContainer {
  display: flex;
  width: 100%;
  top: 0;
}

.card {
  display: flex;
  background-color: var(--color-cosmiclatte);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  margin-bottom: 2.5rem;
  min-height: 75%;
  min-width: 85vw;
  box-shadow: 0px 4px 45px -9px rgba(0, 0, 0, 0.25);
  border-radius: 34px;
  gap: 2.6875rem;
  position: relative;
}

.cardTitle {
  font-family: "Vintage";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #347326;
}

.blob {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-image: url("./assets/blob.svg");
  background-repeat: no-repeat;
  height: 30.1875rem;
  width: 35rem;
  gap: 2rem;
}

.blobTitle {
  font-family: "Vintage";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-cosmiclatte);
}

.entries {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  height: 20.5rem;
}

.detailEntry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 20.4rem;
  color: #f3ecd1;
  font-family: JosefinSans;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.detailEntryValue {
  text-align: right;
  width: 13rem;
}

.backButton {
  position: absolute;
  left: 3rem;
  top: 3rem;
}
