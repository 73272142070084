.Footer {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 19.5rem;
  overflow: hidden;
}

.shape {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  overflow: hidden;
  width: 100%;
  max-width: 100vw;
  margin-top: -1px;
  margin-bottom: -100px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
}

.links {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 95%;
  max-width: 1440px;
  font-family: var(--font-josefin);
  color: var(--color-eggshell);
}

.links_alt {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 95%;
  max-width: 1440px;
  font-family: var(--font-josefin);
  color: var(--color-sap);
}

.links div {
  display: flex;
  gap: 2.5rem;
}

@media (width >= 665px) {
  .links div {
    position: absolute;
  }
}

@media (width <= 664px) {
  .links {
    flex-direction: column;
    gap: 2rem;
  }
  .links div {
    justify-content: center;
  }
}
