* {
    margin: 0;
    padding: 0;
}

.container {
    position: relative;
  }

.default:active {
    transform: scale(0.95);
  }

.clicked:active {
    transform: scale(0.95);
  }

.default {
    color: var(--color-sap);
    display: flex;
    align-items: center;
    justify-content: center;

    height: 3.438rem;
    width: 3.438rem;

    padding: 0.2rem 0 0 0;
    gap: 0.625rem;
    stroke-width: 0;
    border: 0;
    border-radius: 2.813rem;

    font-family: var(--font-josefin);
    font-size: 1.5rem;
    text-align: center;

    transition: all 50ms;
}

.clicked {
    color: var(--color-eggshell);
    background-color: var(--color-sap) !important;
    outline: 0.125rem solid var(--color-sap);
    outline-offset: 0.125rem;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 3.438rem;
    width: 3.438rem;

    padding: 0.2rem 0 0 0;
    gap: 0.625rem;
    stroke-width: 0;
    border: 0;
    border-radius: 2.813rem;

    font-family: var(--font-josefin);
    font-size: 1.5rem;
    text-align: center;

    transition: all 50ms;
}