.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-eggshell);
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 5rem;
  justify-content: center;
  margin-top: 2rem;
  align-items: center;
}

.logo {
  position: absolute;
  left: 0rem;
  top: 1.83rem;
}

.banner {
  color: var(--color-sap);
  font-family: var(--font-vintage);
  font-size: 5rem;
  font-weight: 400;
  text-align: center;
  margin: 2rem 0rem;
}

.social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.social img {
  width: 21rem;
  padding: 0.25rem;
}

.divider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
}

.line {
  height: 0.15rem;
  width: 9rem;
  background-color: var(--color-flame);
  border-radius: 0.15rem;
  margin: 0.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.81rem;
}

.Button {
  padding-bottom: 1rem;
  margin: 0.5rem;
}

.loginText {
  font-family: var(--font-josefin);
  font-size: 20px;
  text-align: center;
  color: var(--color-flame);
  margin: 0.5rem;
}

.loginText p:last-child {
  margin-top: 0.5rem;
}

.loginText a {
  color: var(--color-sap);
  cursor: pointer;
}

.error {
  font-size: 20px;
  font-family: var(--font-josefin);
  color: var(--color-flame);
  margin: 0.5rem;
}

.br {
  display: none;
}

@media (width < 750px) {
  .br {
    display: inline;
  }

  .banner {
    font-size: 3.75rem;
  }

  .loginContainer {
    scale: 0.9;
    margin-top: 0rem;
  }

  .loginText {
    text-align: center;
    font-size: 18px;
  }
}
