.TextField {
    font-family: var(--font-josefin);
    display: flex;
    flex-direction: column;
    color: var(--color-sap);
}

.LabelText {
    margin: 0rem 0rem 0.5rem 1.25rem;
    font-size: 1.25rem;
}

.InputBox {
    font-family: var(--font-josefin);
    font-size: 1.25rem;
    border-radius: 3.25rem;
    border: 0px;
    color: black;
    padding: 0 1.25rem;
    height: 2.5rem;
}

.InputBox::placeholder {
    color: #BEBEBE;
}