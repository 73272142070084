.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-eggshell);
    height: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

.logo {
    position: absolute;
    left: 0rem;
    top: 1.83rem;
}

.banners {
    text-align: center;
    align-items: center;
}

.banner {
    color: var(--color-sap);
    font-family: var(--font-vintage);
    margin-bottom: 50px;
}

.banner h2 {
    font-weight: 400;
    font-size: 3rem;
    margin: 1rem;
}

.smaller_banner {

    font-family: josefinsans;
    font-weight: 400;
    font-size: 25px;
    color: #E4572E;
}

.submitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}