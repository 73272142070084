.page {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: var(--color-eggshell);
  color: var(--color-sap);
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.logo {
  position: absolute;
  left: 0rem;
  top: 1.83rem;

}

.title {
  display: flex;
  align-self: center;
  max-width: 40em;

  margin-top: 5.5rem;
  margin-bottom: 4.938rem;
}

.page h1 {
  font-family: var(--font-vintage);
  font-weight: 400;
  font-size: 10rem;
  line-height: 114%;
}

.sub {
  font-family: var(--font-josefin);
  font-style: normal;
  max-width: 40rem;
  align-self: center;
  margin-bottom: 4.75rem;
}

.sub h2 {
  font-weight: 600;
  font-size: 2.25rem;
  margin-bottom: 1.25rem;
}

.sub p {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 142%;
}

.button {
  padding: 3.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}