.container {
  display: flex;
  align-items: center;
  flex-direction: column;

  background-color: var(--color-cosmiclatte);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  border-radius: 2.688rem;

  min-width: 24.3rem;
  height: 44.4rem;
}

.container:hover {
  cursor: pointer;
}

.profilePicture {
  margin-top: 2.25rem;
  height: 9.125rem;
  width: 9.125rem;
  border-radius: 6.25rem;

  background-position: center;
  background-size: cover;
}

.name {
  font-family: var(--font-vintage);
  color: var(--color-sap);
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 2.625rem;
  margin-top: 0.75rem;
}

.subtitle {
  display: flex;
  flex-direction: row;
  gap: 0.4rem;

  margin-top: 0.25rem;
  margin-bottom: 1.375rem;

  font-family: var(--font-josefin);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  color: black;
}

.textContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 19rem;
}

.contextText {
  font-family: var(--font-josefin);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: black;
  margin-bottom: 0.5rem;
}

.gallery {
  display: flex;
  flex-direction: row;
  width: 19.55rem;
  height: 12.937rem;
  gap: 0.38rem;
  margin-top: 1.813rem;
}

.subgallery {
  display: flex;
  flex-direction: column;
  gap: 0.44rem;
}

.main {
  width: 9.5625rem;
  height: 12.9375rem;
  /* filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.25)); */
  border-radius: 0.625rem;
  background-position: center;
  background-size: cover;
}

.rest {
  height: 6.28019rem;
  width: 9.57188rem;
  /* filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.25)); */
  border-radius: 0.625rem;
  background-position: center;
  background-size: cover;
}

.price {
  margin-top: 1.625rem;
  margin-bottom: 1.313rem;

  font-family: var(--font-josefin);
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
}

@media (max-height: 850px) {
  .container {
    scale: 0.9;
    margin: -2rem;
  }
}
