.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-eggshell);
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.signupContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 5rem;
  justify-content: center;
}

.logo {
  position: absolute;
  left: 0rem;
  top: 1.83rem;
}

.banner {
  color: var(--color-sap);
  font-family: var(--font-vintage);
  font-size: 5rem;
  font-weight: 400;
  text-align: center;
  margin: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.81rem;
}

.TextField {
  padding: 0.5rem;
}

.Button {
  padding: 1rem;
}

.signUpText p {
  font-family: var(--font-josefin);
  font-size: 20px;
  text-align: center;
  color: var(--color-flame);
  margin: 0.5rem;
}

.signUpText a {
  color: var(--color-sap);
  cursor: pointer;
}

.error {
  font-size: 20px;
  font-family: var(--font-josefin);
  color: var(--color-flame);
  margin: 1rem;
}
