.page {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: var(--color-sap);
  color: var(--color-eggshell);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.title {
  display: flex;
  align-self: center;
  max-width: 40em;

  margin-top: 12.5rem;
  margin-bottom: 4.938rem;
}

.page h1 {
  font-family: var(--font-vintage);
  font-weight: 400;
  font-size: 5rem;
  line-height: 114%;
}

.sub {
  font-family: var(--font-josefin);
  font-style: normal;
  max-width: 40rem;
  align-self: center;
  margin-bottom: 4.75rem;
}

.sub h2 {
  font-weight: 600;
  font-size: 2.25rem;
  margin-bottom: 1.25rem;
}

.sub p {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 142%;
}

.myteam {
  margin-top: 10.938rem;
  margin-bottom: 19rem;
  color: var(--color-sap);
  position: relative;
  flex-shrink: 0;
}

.blob {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.myteam h1 {
  font-size: 5rem;
  text-align: center;
  margin-top: 1rem;
  z-index: 1;
  position: relative;
  padding-bottom: 1.5rem;
}

.content {
  display: flex;
  justify-content: space-between;
  max-width: 50rem;
  margin: 0 auto;
  padding: 0.625rem 0;
  /* Move to front */
  z-index: 1;
  position: relative;
}

.content2 {
  display: flex;
  justify-content: space-between;
  max-width: 62.5rem;
  margin: 0 auto;
  padding: 1.25rem 0;
  /* Move to front */
  z-index: 1;
  position: relative;
}

.inside {
  width: 11.55rem;
  height: 15rem;
  padding: 0.625rem;
  text-align: center;
  margin: auto;
}

.inside p {
  font-family: var(--font-josefin);
  font-style: normal;
  font-weight: 600;
}

.potrait {
  width: 9.55rem;
}

.mail {
  width: 1.25rem;
  padding: 0.313rem;
}

.linkedin {
  width: 1.25rem;
  padding: 0.313rem;
}

.name {
  color: #e4572e;
  font-size: 1.15rem;
}

.role {
  color: #367227;
  font-size: 0.8rem;
  padding: 0.188rem;
}

@media (width <= 860px) {
  .myteam {
    scale: 0.4;
    margin: 0;
    margin-top: -10rem;
    margin-bottom: -3rem;
  }

  .myteam h1 {
    font-size: 3.9rem;
  }

  .title {
    scale: 0.75;
    margin: 0;
  }

  .sub {
    scale: 0.75;
    margin: 0;
    margin-top: -5rem;
  }

  .sub p {
    font-size: 1.5rem;
  }

  .content {
    justify-content: center;
  }

  .content2 {
    justify-content: center;
  }
}
