.Navbar-container {
  display: flex;
  flex-direction: row;
  height: 6.25rem;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: var(--color-eggshell);
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 4px 45px -9px rgba(0, 0, 0, 0.25);
  z-index: 10000;
}

.Navbar-container-alt {
  display: flex;
  flex-direction: row;
  height: 6.25rem;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: var(--color-sap);
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 4px 45px -9px rgba(0, 0, 0, 0.25);
  z-index: 10000;
}

.Navbar-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 2rem;
  gap: 1.52rem;
  align-items: center;
  z-index: 1;
}

.Navbar-container-fixed {
  display: flex;
  flex-direction: row;
  height: 6.25rem;
  width: 100%;
}

.Navbar-container-fixed-alt {
  display: flex;
  flex-direction: row;
  height: 6.25rem;
  width: 100%;
}

.Navbar-button-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 10000;
}

.Navbar-mobile-dropdown {
  padding: 0.5rem;
}

/* For sliding in animation */
.slide-in {
  transform: translateY(0px);
  transition: transform 0.8s ease;
}

/* For sliding out animation */
.slide-out {
  transform: translateY(-200px);
  transition: transform 0.5s ease;
}

@media (max-width: 554px) {
  .Navbar-button-container {
    display: none;
  }

  .Navbar-content {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
  }
}

@media (width >= 554px) {
  .Navbar-mobile-dropdown {
    display: none;
  }
}
