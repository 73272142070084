.LabelText {
  margin: 0rem 0rem 0.5rem 1.25rem;
  font-size: 1.25rem;
}

.fileInput {
  font-family: var(--font-josefin);
  display: flex;
  flex-direction: column;
  color: var(--color-sap);
}

.fileInput input {
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  width: 13rem;
}
