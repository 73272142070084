.Logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.logo {
  height: 5rem;
}

.logo:hover {
  cursor: pointer;
}
