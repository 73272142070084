* {
    margin: 0;
    padding: 0;
}

.page {
    background-color: var(--color-eggshell);
    overflow: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 90vh;
}

.logo {
    position: absolute;
    left: 0rem;
    top: 1.83rem;
    
}

.banner {
    color: var(--color-sap);
    font-family: var(--font-vintage);
    text-align: center;
}

.banner h2 {
    font-weight: 400;
    font-size: 3rem;
    margin: 1rem;
}

.banner h1 {
    font-weight: 400;
    font-size: 5.5rem;
    margin: 1rem;
}

.formheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.TextField {
    padding: 0.5rem;
}

.Button {
    padding: 1rem;
}

.error {
    font-size: 20px;
    font-family: var(--font-josefin);
    color: var(--color-flame);
    margin: 1rem;
}

.success {
    font-size: 20px;
    font-family: var(--font-josefin);
    color: var(--color-sap);
    margin: 1rem;
}

@media (width < 750px) {
    .banner {
        margin-top: 1rem;
    }

    .banner h2 {
        font-size: 1.5rem;
        margin: 0;
    }

    .banner h1 {
        font-size: 3rem;
        margin: 0;
    }

    .form {
        scale: 0.9;
    }

    .formheader {
        flex-direction: column;
    }

    .success {
        max-width: 15rem;
        text-align: center;
    }
}