.menuContainer {
    height: 625px;
    width: 350px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.menuHeader {
    font-family: var(--font-josefin);
    font-size: 3rem;
    font-weight: 700;
}

.menuSubheaders {
    font-family: var(--font-josefin);
    font-size: 2rem;
    font-weight: 600;
    margin-top: 2rem;
}

.menuText {
    font-family: var(--font-josefin);
    text-align: center;
    font-size: 1.25rem;
    font-weight: 300;
    
    margin-top: 0.5rem;
}

.menuLine {
    height: 0.5px;
    width: 11rem;
    color: black;

    margin: 2rem auto;
}