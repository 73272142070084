.page {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-eggshell);
  overflow-x: hidden;
  height: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
}

.navbarContainer {
  display: flex;
  width: 100%;
  /* position: absolute; */
  top: 0;
  /* background-color: red; */
}

.logo {
  position: absolute;
  left: 3.25rem;
  top: 2.625rem;
}

.banner {
  color: var(--color-sap);
  font-family: var(--font-vintage);
  font-size: 5rem;
  font-weight: 400;
  text-align: center;
  margin: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TextField {
  padding: 0.5rem;
}

.Button {
  padding: 1rem;
  margin-top: 2rem;
}

.aboutYou p {
  font-family: var(--font-josefin);
  font-size: 1.25rem;
  text-align: center;
  color: var(--color-flame);
  margin: 0.5rem;
}

.aboutYou a {
  color: var(--color-sap);
}

.textArea {
  font-family: var(--font-josefin);
  font-size: 1.25rem;
  border-radius: 1.175rem;
  border: 0px;
  color: black;
  height: 2.5rem;
  padding: 1.25rem;
  padding-bottom: 10rem;
  width: 19rem;
}

.fields {
  flex-direction: row;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

@media (width <= 1130px) {
  .fields {
    flex-direction: column;
  }
}
