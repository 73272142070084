.page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-eggshell);
  /* padding-bottom: 3rem; */
  height: 100vh;
}

.cardContainer {
  display: flex;
  /* background-color: blue; */
  flex-direction: column;
  height: 100%;
  justify-content: center;
  /* align-items: center; */
}

.navbarContainer {
  display: flex;
  width: 100%;
  /* position: absolute; */
  top: 0;
  /* background-color: red; */
}

.card {
  display: flex;
  background-color: var(--color-cosmiclatte);
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 4rem;
  margin-bottom: 2.5rem;
  min-height: 75%;
  min-width: 85vw;
  box-shadow: 0px 4px 45px -9px rgba(0, 0, 0, 0.25);
  border-radius: 34px;
  gap: 2rem;
  position: relative;
}

.cardTitle {
  font-family: Vintage;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;

  display: flex;
  align-items: center;
  text-align: left;

  color: #347326;
}

.backButton {
  position: absolute;
  left: 3rem;
  top: 3rem;
}

.bookingRow {
  display: flex;
  flex-direction: row;
  gap: 2.75rem;
  overflow-x: scroll;
  max-width: 85vw;
}

.bookingBubble {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.12rem;
  min-width: 10.344375rem;
}

.bookingBubblePhoto {
  height: 10rem;
  width: 10rem;
  border-radius: 5rem;
  background-color: var(--color-flame);
}

.bookingBubbleText {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  font-family: JosefinSans;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 0.1rem;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.date {
  color: var(--color-sap);
  text-align: center;
  font-family: JosefinSans;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.name {
  color: var(--color-flame);
  font-family: JosefinSans;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.buttonAndTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.bookingRow::-webkit-scrollbar {
  width: 0 !important;
}

.noBookingText {
  margin-top: 5rem;
  font-family: JosefinSans;
  font-size: 2rem;
  text-align: center;
  vertical-align: middle;
  color: var(--color-flame)
}

@media (max-height: 850px) {
  .cardContainer {
    scale: 0.9;
    margin: -2rem;
  }
}