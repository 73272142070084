* {
    margin: 0;
    padding: 0;
}

.container {
    position: relative;
  }

/* button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 2rem;
    width: 7.5rem;

    padding: 8px 20px 5px;
    gap: 0.625rem;
    stroke-width: 0;
    border: 0;
    border-radius: 45px;

    font-family: var(--font-josefin);
    font-size: 1.125rem;
    text-align: center;

    transition: all 50ms;
} */

.default:active {
    transform: scale(0.95);
  }

.clicked:active {
    transform: scale(0.95);
  }

.default {
    color: var(--color-sap);
    display: flex;
    align-items: center;
    justify-content: center;

    height: 2rem;
    width: 7.5rem;

    padding: 8px 20px 5px;
    gap: 0.625rem;
    stroke-width: 0;
    border: 0;
    border-radius: 45px;

    font-family: var(--font-josefin);
    font-size: 1.125rem;
    text-align: center;

    transition: all 50ms;
}

.clicked {
    color: var(--color-eggshell);
    background-color: var(--color-sap) !important;
    outline: 2px solid var(--color-sap);
    outline-offset: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 2rem;
    width: 7.5rem;

    padding: 8px 20px 5px;
    gap: 0.625rem;
    stroke-width: 0;
    border: 0;
    border-radius: 45px;

    font-family: var(--font-josefin);
    font-size: 1.125rem;
    text-align: center;

    transition: all 50ms;
}