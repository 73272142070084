.dropdown-container {
  position: relative;
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 3px 0px 0px 0px;
  stroke-width: 0px;
  border: 0px;
  width: 8.125rem;
  height: 2rem;
  
  background: rgba(54, 114, 39, 0.17);
  border-radius: 41px;

  color: var(--color-sap);
  font-family: var(--font-josefin);
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.125rem;
  transition: all 200ms ease;

  cursor: pointer;
}

.dropdown-button:hover {
  background: rgba(54, 114, 39, 0.30);
}

.dropdown-button-alt:active,
.dropdown-button:active {
  transform: scale(0.95);
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 60px;
  transform: translateY(-20px);
  
  width: 8.25rem;
  border-radius: 15px;
  
  background-color: var(--color-sap);
}

.dropdown-content a {
  display: flex;
  flex-direction: column;
  color: var(--color-eggshell);
  font-family: var(--font-josefin);
  font-style: normal;
  font-size: 1.125rem;
  gap: 1.188rem;
  margin: 1.5rem 1.375rem;
}

.dropdown-content a:visited {
  color: var(--color-eggshell);
}

.dropdown-container-alt {
  position: relative;
}

.dropdown-button-alt {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 3px 0px 0px 0px;
  stroke-width: 0px;
  border: 0px;
  width: 8.125rem;
  height: 2rem;
  
  background: rgba(243, 236, 209, 0.17);
  border-radius: 41px;

  color: var(--color-eggshell);
  font-family: var(--font-josefin);
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.125rem;
  transition: all 200ms ease;

  cursor: pointer;
}

.dropdown-button-alt:hover {
  background: rgba(243, 236, 209, 0.3);
  color: rgba(243, 236, 209, 1);
}

.dropdown-content-alt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 60px;
  transform: translateY(-20px);
  
  width: 8.25rem;
  border-radius: 15px;
  
  background-color: var(--color-eggshell);
}

.dropdown-content-alt a {
  display: flex;
  flex-direction: column;
  color: var(--color-sap);
  font-family: var(--font-josefin);
  font-style: normal;
  font-size: 1.125rem;
  gap: 1.188rem;
  margin: 1.5rem 1.375rem;
}

.dropdown-content-alt a:visited {
  color: var(--color-sap);
}
