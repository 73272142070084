.TextArea {
  font-family: var(--font-josefin);
  display: flex;
  flex-direction: column;
  color: var(--color-sap);
  padding: 0.5rem;
}

.LabelText {
  margin: 0.5rem;
  margin-left: 1.25rem;
  font-size: 1.25rem;
}

.InputBox {
  font-family: var(--font-josefin);
  font-size: 1.25rem;
  border-radius: 1.175rem;
  border: 0px;
  color: black;
  height: 2.5rem;
  padding: 1.25rem;
  padding-bottom: 10rem;
}

.InputBox::placeholder {
  color: #bebebe;
}
