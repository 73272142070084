/****************/
/* First Column */
/****************/
.chefBoxContainer {
  display: flex;
  flex-direction: row;
  /* width: 100vw; */
  overflow-x: hidden;
  margin: -3rem 0rem;
  padding: 3rem 3rem 6rem 3rem;
  gap: 3rem;
}

.chefBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

  background-color: var(--color-cosmiclatte);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  border-radius: 2.688rem;
  gap: 4.44rem;
  padding: 0rem 4.44rem;
  height: 44.4rem;
}

.chefCardContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.arrow {
  margin: -1rem;
  padding-right: 21rem;
}

.chefCardProfile {
  height: 9.125rem;
  width: 9.125rem;
  border-radius: 6.25rem;

  background-position: center;
  background-size: cover;
}

.chefCardName {
  font-family: var(--font-vintage);
  color: var(--color-sap);
  font-weight: 400;
  font-size: 1.875rem;

  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

/* .chefCardSubtitle {
    display: flex;
    flex-direction: row;
    gap: 0.4rem;

    margin-top: 0.25rem;
    margin-bottom: 1.375rem;

    font-family: var(--font-josefin);
    font-weight: 400;
    font-size: 1rem;
    color: black;
} */

.chefCardTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 19rem;
}

.chefCardContextText {
  font-family: var(--font-josefin);
  font-weight: 400;
  font-size: 1.25rem;
  color: black;
  margin-bottom: 0.5rem;
}

.chefCardGallery {
  display: flex;
  flex-direction: row;
  width: 19.55rem;
  height: 12.937rem;
  gap: 0.38rem;
  margin: 1.813rem 0rem;
}

.chefCardSubgallery {
  display: flex;
  flex-direction: column;
  gap: 0.44rem;
}

.chefCardMainPhoto {
  width: 9.5625rem;
  height: 12.9375rem;
  /* filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.25)); */
  border-radius: 0.625rem;
  background-position: center;
  background-size: cover;
}

.chefCardSecondaryPhotos {
  height: 6.28019rem;
  width: 9.57188rem;
  /* filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.25)); */
  border-radius: 0.625rem;
  background-position: center;
  background-size: cover;
}

.chefCardGalleryText {
  font-family: var(--font-josefin);
  color: var(--color-sap);
  font-size: 1.125rem;
  font-weight: 400;

  margin-bottom: 2.75rem;
  margin-top: 0rem;
}

.chefCardPrice {
  margin-top: 1.625rem;
  margin-bottom: 1.313rem;

  font-family: var(--font-josefin);
  font-weight: 600;
  font-size: 1.25rem;
}

/****************/
/* Third Column */
/****************/

.aboutAndReviewContainer {
  display: flex;
  flex-direction: column;
  width: 22.5rem;
}

.aboutAndReviewHeader {
  font-family: var(--font-vintage);
  color: var(--color-sap);
  font-size: 2.5rem;
  font-weight: 400;
}

.bio {
  font-family: var(--font-josefin);
  font-size: 1.125rem;
  font-weight: 400;

  margin-top: 1rem;
  margin-bottom: 2.5rem;
}

.reviewHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 1rem;
}

.reviewSubheader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.reviewText {
  font-family: var(--font-josefin);
  font-size: 1.25rem;
  font-weight: 400;
}

.reviewSubtext {
  font-family: var(--font-josefin);
  font-size: 1.125rem;
  font-weight: 400;
}

.reviewScoreboard {
  display: flex;
  justify-content: space-between;

  margin-bottom: 3rem;
  height: 5.25rem;

  font-family: var(--font-josefin);
  font-size: 1.25rem;
  font-weight: 400;
}

.metricContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 5.25rem;
  width: 8.25rem;
}

.barFlexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 5.25rem;
  width: 9.25rem;
}

.barContainer {
  width: 9.25rem;
  background-color: var(--color-cosmiclatte);
}

.reviewBar {
  height: 5px;
  background-color: var(--color-flame);
  border-radius: 1rem;
}

.metricScoreContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 5.25rem;
}

.recentReviewContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
}

@media (max-height: 850px) {
  .chefBox {
    scale: 0.9;
    margin: -2rem;
  }
}
